import * as React from "react";
import { container,contentContainer,header } from "./GenericPage.module.scss";
import Row from "react-bootstrap/Row";
import Container from "react-bootstrap/Container";

export const ContentContainer = (props: { children: any }): JSX.Element => {
    return (<div className={contentContainer}>{props.children}</div>);
};
export const StyledContainer = (props: { children: any }): JSX.Element => {
    return (<Container className={container}>{props.children}</Container>);
};
export const StyledRow = (props: { children: any }): JSX.Element => {
    return (<Row className={container}>{props.children}</Row>);
};

