import React from "react";
import { Container } from "react-bootstrap";
import { Banner ,Overlay,CtaWrapper} from "./MediaBannerHelpers";
import { IMediaBannerProps } from "./MediaBannerProps";
import { graphql } from "gatsby";

const MediaBanner = (node: IMediaBannerProps, ...props: any) => {
    const minHeight = 350;
    const desktopImage = node?.node?.relationships.desktopImage;
    const mobileImage = node?.node?.relationships.mobileImage;
    let bannerSources = null;
    if (desktopImage) {
      bannerSources = [
        mobileImage,
        {
          ...desktopImage,
          media: `(min-width: 491px)`,
        },
      ];
    }
    return (<Container fluid className="p-0 position-relative">
        <Banner
            Tag="div"
            className="section-banner h-100"
            fluid={bannerSources}
            height={minHeight}>
            <Overlay/>
            <CtaWrapper {...node}/>
        </Banner>
  </Container>);
};

export default MediaBanner;

export const fragment = graphql`
  fragment MediaBanner on media__banner {
    id
    relationships {
      desktopImage:
        field_media_image {      
            publicUrl
            gatsbyImage(
              width: 1920
              height: 400
              placeholder: BLURRED
              formats: [AUTO, WEBP]
            )
        }  
      mobileImage: 
        field_media_image {      
          publicUrl
          gatsbyImage(
            width: 490
            height: 200
            placeholder: BLURRED
            formats: [AUTO, WEBP]
          )
        }
    }
  }
`;
