import * as React from "react";
import { banner, overlay, textContainer } from "./MediaBanner.module.scss";
import BackgroundImage from "gatsby-background-image";
import { getImage } from "gatsby-plugin-image";
import { convertToBgImage } from "gbimage-bridge";
import Container from "react-bootstrap/Container";

export const Banner = (props: any) => {
    const _image = props.fluid && props.fluid[1] ? getImage(props.fluid[1]) : null;
    const _bgImage = _image ? convertToBgImage(_image) : null;
    return (_bgImage ? <BackgroundImage {..._bgImage}
        preserveStackingContext
        className={banner}>
        <div className="overlay"/>
        <Container fluid="sm" className="cta-wrapper d-flex flex-column justify-content-center">
            {props.children}
        </Container>
    </BackgroundImage> : null);
};

export const Overlay = () => {
    return (
        <div className={overlay} />
    );
};

export const CtaWrapper = (props: {children:JSX.Element}) => {
    return (<Container fluid="sm" className={textContainer}>
        {props.children}
    </Container>);
};